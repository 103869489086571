
import './Styles/DevBadge.css'

const DEV_BUILD = `${process.env.REACT_APP_DEV_BUILD}` == 'true'

export const DevBadge = () => {
    if (!DEV_BUILD) {
        return null;
    }
    return (
        <div className='dev-badge'>
            <div>Dev Build</div>
            <div>v{process.env.REACT_APP_VERSION}</div>
        </div>
    )
}