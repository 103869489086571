import React, {useEffect} from "react";
import {Button, Spinner} from "react-bootstrap";
import {CheckLg, ExclamationCircle, Trash} from "react-bootstrap-icons";
import {SimpleTooltip} from "../SimpleTooltip";
import {FileParseException, FileParser} from "../../Core/FileParser";

export interface FileProps<T> {
    file: File,
    parser: FileParser<T>,
    state: FileState<T>,
    // eslint-disable-next-line no-unused-vars
    dispatchState: (event: FileEvent<T>) => void,
    onRemove: () => void
}

export type FileLoadingState<T> =
    {
        type: 'loading'
    } |
    {
        type: 'success',
        items: T[]
    } |
    {
        type: 'error',
        message: string
    }

export interface FileState<T> {
    fileState: FileLoadingState<T>
}

export type FileEvent<T> =
    {
        type: 'ERROR',
        message: string
    } |
    {
        type: 'LOADED',
        items: T[]
    }

export function newFileState<T>(): FileState<T> {
    return {
        fileState: { type: 'loading' }
    }
}

export function dispatchFileState<T>(state: FileState<T>, event: FileEvent<T>): FileState<T> {
    switch (event.type) {
        case "ERROR":
            return {...state, fileState: {type: "error", message: event.message}}
        case "LOADED":
            return {...state, fileState: {type: 'success', items: event.items}}
    }
}

export function FileView<T>(props: FileProps<T>) {
    const {dispatchState, state} = props
    useEffect(() => {
        if (state.fileState.type === 'loading') {
            const fileReader = new FileReader()
            fileReader.onerror = (event) => {
                if (event.target !== null) {
                    dispatchState({ type: 'ERROR', message: event.target.error?.message ?? 'Unable to load file' })
                }
            }
            fileReader.onprogress = e => console.error('progress', props.file.name, e)
            fileReader.onload = (event) => {
                if (event.target !== null) {
                    const content = event.target.result;
                    if (content === null || typeof content !== 'string') {
                        dispatchState({ type: 'ERROR', message: 'No content loaded from file' })
                        return
                    }
                    try {
                        let result = props.parser.parseFileContent(content);
                        dispatchState({type: 'LOADED', items: result})
                    } catch (e) {
                        if (e instanceof FileParseException) {
                            dispatchState({type: 'ERROR', message: e.parseMessage});
                        } else {
                            dispatchState({type: 'ERROR', message: `Unknown error occurred while parsing file: ${e}`})
                        }
                    }
                }
            }
            fileReader.onabort = e => console.error('abort', props.file.name, e)
            fileReader.readAsText(props.file)
            return () => fileReader.abort()
        }
    }, [props.file])
    return (
        <tr>
            <td className='bot-create-table-cell'>
                {props.file.name} {props.file.size} bytes
            </td>
            <td className='bot-create-table-cell'>
                {state.fileState.type === 'loading' ? <Spinner /> : null}
                {state.fileState.type === 'error' ?
                    <SimpleTooltip tooltip={state.fileState.message}>
                        <ExclamationCircle color={'red'} />
                    </SimpleTooltip> : null}
                {state.fileState.type === 'success' ? <CheckLg color='green' /> : null}
            </td>
            <td className='bot-create-table-cell'>
                {state.fileState.type === 'success' ? state.fileState.items.length : ''}
            </td>
            <td className='bot-create-table-cell'>
                <Button size='sm' variant='outline-danger' onClick={() => props.onRemove()}><Trash /></Button>
            </td>
        </tr>
    )
}