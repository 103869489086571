export interface FileParser<T> {
    parseFileContent(content: string): T[]
}

export class FileParseException extends Error {

    parseMessage: string

    constructor(parseMessage: string) {
        super(parseMessage);
        this.parseMessage = parseMessage;
    }

}