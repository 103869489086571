import {Button, Dropdown, Modal, Spinner, Table} from "react-bootstrap";
import {ProxyInput} from "./ProxyInput";
import {
    AddProxiesRequest,
    AddProxiesResponse,
    getProxyTypeName,
    PROXY_TYPES,
    ProxyData,
    ProxyType
} from "../Core/Model";
import {useEffect, useReducer} from "react";
import {Check2Circle, ExclamationTriangleFill, Trash} from "react-bootstrap-icons";

import './Styles/Proxies.css'
import {callDispatch, CallState, CallUpdate, newCall} from "../Utils/Calls";
import {useApi} from "../Core/ApiContextProvider";
import {ErrorView} from "../Utils/ErrorView";
import {SimpleTooltip} from "../Utils/SimpleTooltip";

export interface CreateProxyModalProps {
    show: boolean,
    // eslint-disable-next-line no-unused-vars
    onClose: (changed: boolean) => void
    initialProxies?: ProxyData[]
}

interface CreateProxyState {
    proxies: ProxyData[]
    missingSchema: number
    defaultProxyType: ProxyType
    create: CallState<AddProxiesResponse>
}

type CreateProxyEvent = {
    type: 'CALL_UPDATE',
    call: 'create',
    update: CallUpdate
} | {
    type: 'ADD_PROXY',
    proxy: ProxyData
} | {
    type: 'REMOVE_PROXY',
    index: number
} | {
    type: 'RESET',
    proxies: ProxyData[]
} | {
    type: 'SET_DEFAULT_PROXY_TYPE',
    proxyType: ProxyType
}

function newState(initialProxies: ProxyData[]): CreateProxyState {
    return {
        proxies: initialProxies,
        missingSchema: initialProxies.filter(e => e.schema === undefined || e.schema.length === 0).length,
        create: newCall(),
        defaultProxyType: ProxyType.SOCKS5
    }
}

export const CreateProxyModal = (props: CreateProxyModalProps) => {
    const api = useApi()
    const [state, dispatchState] = useReducer((state: CreateProxyState, event: CreateProxyEvent) => {
        if (event.type === 'ADD_PROXY') {
            return { ...state, proxies: [...state.proxies, event.proxy], missingSchema: state.missingSchema + (event.proxy.schema === undefined || event.proxy.schema.length === 0 ? 1 : 0)}
        } else if (event.type === 'REMOVE_PROXY') {
            const nextProxies = state.proxies.filter((_, index) => index !== event.index)
            return { ...state, proxies: nextProxies, missingSchema: nextProxies.filter(e => e.schema === undefined || e.schema.length === 0).length  }
        } else if (event.type === 'CALL_UPDATE') {
            switch (event.call) {
                case 'create': {
                    return { ...state, create: callDispatch(state.create, event.update) }
                }
            }
        } else if (event.type === "RESET") {
            return newState(event.proxies)
        } else if (event.type === 'SET_DEFAULT_PROXY_TYPE') {
            return {...state, defaultProxyType: event.proxyType}
        }
        return state
    }, newState(props.initialProxies ?? []))
    useEffect(() => {
        dispatchState({ type: 'RESET', proxies: props.initialProxies ?? [] })
    }, [props.initialProxies])
    const onClose = () => {
        dispatchState({ type: 'RESET', proxies: [] })
        props.onClose(state.create.data !== null)
    }
    const onAdd = (data: ProxyData) => {
        dispatchState({ type: 'ADD_PROXY', proxy: data })
        return true;
    }
    const onRemove = (index: number) => {
        dispatchState({ type: 'REMOVE_PROXY', index: index })
    }
    const onSubmit = () => {
        const proxies = state.proxies
        if (proxies.length === 0) {
            return
        }
        const defaultType = state.defaultProxyType
        const stringProxies = proxies.map(e => {
            const schema = e.schema === undefined || e.schema.length === 0 ? defaultType : e.schema;
            if (e.username === undefined) {
                return `${schema}://${e.host}:${e.port}`
            }
            if (e.password === undefined) {
                return `${schema}://${e.host}:${e.port}:${e.username}:`
            }
            return `${schema}://${e.host}:${e.port}:${e.username}:${e.password}`
        })
        const request: AddProxiesRequest = { proxies: stringProxies }
        dispatchState({ type: 'CALL_UPDATE', call: 'create', update: { type: 'LOADING', message: 'Adding proxies...' } })
        api.proxiesAdd(request)
            .then(e => dispatchState({ type: 'CALL_UPDATE', call: 'create', update: { type: 'DATA', data: e } }))
            .catch(e => dispatchState({ type: 'CALL_UPDATE', call: 'create', update: { type: 'ERROR', error: e } }))
    }
    const onDefaultProxyTypeSelect = (eventKey: any) => {
        if (eventKey === undefined || eventKey === null || eventKey.length === 0) {
            return
        }
        const selectedType = PROXY_TYPES.find(e => e === eventKey)
        if (selectedType === undefined) {
            console.warn(`unknown proxy type selected: '${eventKey}'`)
            return
        }
        dispatchState({ type: 'SET_DEFAULT_PROXY_TYPE', proxyType: selectedType })
    }
    const getContent = () => {
        if (state.create.loading) {
            return (
                <div className='loading-wrapper'>
                    <Spinner/>
                    {state.create.message !== null && <span className='loading-message'>
                        {state.create.message}
                   </span>}
                </div>
            )
        }
        if (state.create.data) {
            return (
                <div className='success-message'>
                    <Check2Circle color='green' />
                    <div>{state.create.data.added_proxies.length} proxies created successfully</div>
                </div>
            )
        }
        return (
            <>
                <Table size='sm'>
                    <thead>
                    <tr>
                        <th>Schema</th>
                        <th>Host</th>
                        <th>Port</th>
                        <th>Username</th>
                        <th>Password</th>
                        <th></th>
                    </tr>
                    </thead>
                    <tbody>
                    {state.proxies.map((proxy, index) => {
                        return (
                            <tr key={`proxy-${index}`}>
                                <td>{proxy.schema !== undefined && proxy.schema.length > 0 ? proxy.schema : <SimpleTooltip tooltip='Proxy schema is not set. Default selected will be used instead'><ExclamationTriangleFill color='orange'/> <span style={{opacity: 0.4}}>{state.defaultProxyType}</span></SimpleTooltip>}</td>
                                <td>{proxy.host}</td>
                                <td>{proxy.port}</td>
                                <td>{proxy.username ?? 'N/A'}</td>
                                <td>{proxy.password ?? 'N/A'}</td>
                                <td className='proxy-create-table-cell-end'>
                                    <Button size='sm' variant='outline-danger' onClick={() => onRemove(index)}>
                                        <Trash />
                                    </Button>
                                </td>
                            </tr>
                        )
                    })}
                    </tbody>
                </Table>
                <ProxyInput enableAdd={true} onAdd={onAdd} />
                <ErrorView call={state.create} name={'create proxies'} />
            </>
        )
    }
    return (
        <Modal size='xl' show={props.show} fullscreen='lg-down'>
            <Modal.Header>
                <Modal.Title>
                    Create proxy
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {getContent()}
            </Modal.Body>
            <Modal.Footer>
                {!state.create.data && state.missingSchema > 0 && <SimpleTooltip tooltip='Some of the proxies are missing schema. Set default one or resubmit these proxy with appropriate schema'>
                    <Dropdown onSelect={onDefaultProxyTypeSelect}>
                        <Dropdown.Toggle disabled={state.proxies.length === 0 || state.create.loading} variant='warning' className='icon-button'>
                            <ExclamationTriangleFill />
                            <span>Default schema: {getProxyTypeName(state.defaultProxyType)}</span>
                        </Dropdown.Toggle>
                        <Dropdown.Menu>
                            {PROXY_TYPES.map(type => {
                                return (
                                    <Dropdown.Item key={type} eventKey={type}>
                                        {getProxyTypeName(type)}
                                    </Dropdown.Item>
                                )
                            })}
                        </Dropdown.Menu>
                    </Dropdown>
                </SimpleTooltip>}
                {!state.create.data && <Button disabled={state.proxies.length === 0 || state.create.loading} onClick={onSubmit}>Create</Button>}
                <Button disabled={state.create.loading} onClick={onClose}>Close</Button>
            </Modal.Footer>
        </Modal>
    )
}