import {CallState} from "./Calls";
import {Button} from "react-bootstrap";
import {useAuthContext} from "../Core/AuthContextProvider";

export interface TableErrorViewProps<T> {
    call: CallState<T>
    name: string
    colSpan: number
}

export function TableErrorView<T>(props: TableErrorViewProps<T>) {
    const {clearAuth} = useAuthContext()
    if (props.call.error !== null && !props.call.loading) {
        const isAuthError = props.call.error.response && [401, 403].includes(props.call.error.response.status)
        return (
            <tr>
                <td colSpan={props.colSpan} className='center-text'>
                    {isAuthError ?
                          <div>Authorization error occurred performing {props.name}. Consider log out and log in again or contact support!</div>
                        : <div>Error occurred performing {props.name}</div>}
                    <div>{props.call.error.message}</div>
                    {isAuthError &&
                        <div>
                            <Button size='sm' variant='outline-danger' onClick={() => clearAuth}>
                                Logout
                            </Button>
                        </div>
                    }
                </td>
            </tr>
        )
    }
    return null
}