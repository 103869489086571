import './Style/App.css';
import {ApiProvider} from "../Core/ApiContextProvider";
import {Environment} from "../Core/Environment";
import {
    BrowserRouter,
    Route,
    Routes
} from "react-router-dom";
import {Dashboard} from '../Dashboard/Dashboard';
import {TaskDetailsPage} from "../Tasks/TaskDetailsPage";
import {UserCacheProvider} from "../Utils/UserCache";
import {GroupDetailsPage} from "../Groups/GroupDetailsPage";
import {AuthGuard} from "../Utils/AuthGuard";
import {Login} from "../Login/Login";
import {HomeRouter} from "../Utils/HomeRouter";
import {NavOutlet} from "../Utils/NavOutlet";

export const App = () => {
  return (
      <ApiProvider baseUrl={`${Environment.BASE_URL}`}>
          <UserCacheProvider>
              <BrowserRouter basename={Environment.BASENAME}>
                  <Routes>
                      <Route path='/' element={<HomeRouter />} />
                      <Route path='/login' element={<Login />} />
                      <Route element={<AuthGuard />}>
                          <Route element={<NavOutlet />} >
                              <Route path='/dashboard' element={<Dashboard />} />
                              <Route path='/task/:taskId' element={<TaskDetailsPage />} />
                              <Route path='/bot-group/:groupId' element={<GroupDetailsPage />} />
                          </Route>
                      </Route>
                  </Routes>
              </BrowserRouter>
          </UserCacheProvider>
      </ApiProvider>
  );
}