import React, {ReactElement, ReactNode} from "react";
import {UserInfo} from "./Model";
import {useCache} from "../Utils/UserCache";

export interface AuthState {
    user: UserInfo | null
}

export interface AuthContextState {
    authState: AuthState
    setAuth: (user: UserInfo) => void
    clearAuth: () => void
}

const initialAuthContextStat: AuthContextState = {
    authState: { user: null },
    setAuth: () => {},
    clearAuth: () => {}
}

export const AuthContext = React.createContext<AuthContextState>(initialAuthContextStat)

export const useAuthContext: () => AuthContextState = () => React.useContext(AuthContext)

type AuthEvent = {
    type: 'UPDATE_USER',
    user: UserInfo | null
}

export const AuthProvider = (props: { children: ReactElement | ReactNode }) => {
    const cache = useCache()
    const [authState, dispatch] = React.useReducer((state: AuthState, event: AuthEvent) => {
        switch (event.type) {
            case "UPDATE_USER": {
                return { ...state, user: event.user }
            }
        }
        return state
    }, cache.json('auth_state', { user: null }))

    return (
        <AuthContext.Provider value={{
            authState,
            setAuth: (user) => {
                cache.setJson('auth_state', { ...authState, user: user })
                dispatch({ type: 'UPDATE_USER', user: user })
            },
            clearAuth: () => {
                cache.remove('auth_state')
                dispatch({ type: 'UPDATE_USER', user: null })
            }
        }}>
            {props.children}
        </AuthContext.Provider>
    )
}