import {CreateBotCredentials} from "./Model";
import {FileParseException, FileParser} from "./FileParser";
import {undefinedIfEmpty} from "../Utils/Utils";

export class BotFileParser implements FileParser<CreateBotCredentials> {
    parseFileContent(content: string): CreateBotCredentials[] {
        const lines = content.split('\n')
        let bots: CreateBotCredentials[] = []
        for (let i = 0; i < lines.length; i++) {
            try {
                const line = lines[i].trim();
                if (line.length === 0) {
                    continue
                }
                const separationIndex = line.indexOf(':')
                const lastSeparationIndex = line.lastIndexOf(':')
                if (separationIndex === -1) {
                    // noinspection ExceptionCaughtLocallyJS
                    throw new FileParseException(`File parse failure on line ${i + 1}: No ':' found` )
                }
                const login = line.substring(0, separationIndex).trim()
                const password = lastSeparationIndex > separationIndex
                    ? line.substring(separationIndex + 1, lastSeparationIndex).trim()
                    : line.substring(separationIndex + 1, line.length).trim()
                const sessionId = lastSeparationIndex > separationIndex
                    ? line.substring(lastSeparationIndex + 1, line.length).trim()
                    : ''
                bots.push({ login: login, password: password, session_key: undefinedIfEmpty(sessionId) })
            } catch (e) {
                if (e instanceof FileParseException) {
                    throw e
                }
                throw new FileParseException(`File parse failure on line ${i + 1}: ${e}`);
            }
        }
        return bots;
    }
}