import {Button, Form, FormGroup, InputGroup, Modal} from "react-bootstrap";
import {CheckLg, X, XLg} from "react-bootstrap-icons";
import {SimpleTooltip} from "../Utils/SimpleTooltip";
import {IdRange, parseIds} from "../Utils/Utils";
import {ChangeEvent, useState} from "react";
import {Variant} from "react-bootstrap/types";


interface ProxiesAction {
    title: string
    variant: Variant
    handler: (ranges: IdRange[]) => void
}

export interface SelectProxiesModalProps {
    show: boolean
    onClose?: () => void
    actions: ProxiesAction[]
}

interface SelectProxiesState {
    ranges: IdRange[]
    valid: boolean
    message: string
}

function newState(): SelectProxiesState {
    return {
        ranges: [],
        valid: false,
        message: 'Enter some proxies'
    }
}

export const SelectProxiesModal = (props: SelectProxiesModalProps) => {
    const [inputRanges, setInputRanges] = useState<SelectProxiesState>(newState())
    const onClose = () => {
        const handler = props.onClose
        if (handler !== undefined) {
            setInputRanges(newState())
            handler();
        }
    }
    const onAction = (action: ProxiesAction) => {
        if (!inputRanges.valid) {
            return
        }
        const ranges = inputRanges.ranges
        onClose()
        action.handler(ranges)
    }
    const onInputChange = (event: ChangeEvent<HTMLInputElement>) => {
        const value = event.target.value.trim()
        try {
            const ranges = parseIds(value, 4000)
            setInputRanges({ valid: true, message: `Ok. ${ranges.length} ${ranges.length === 1 ? 'range' : 'ranges'}`, ranges: ranges})
        } catch (e) {
            if (e instanceof Error) {
                setInputRanges({ valid: false, message: e.message, ranges: [] })
            } else {
                setInputRanges({ valid: false, message: `Could not validate input`, ranges: [] })
            }
        }
    }
    return (
        <Modal show={props.show} size='lg' fullscreen='lg-down'>
            <Modal.Header>
                <Modal.Title>Select proxies</Modal.Title>
                {props.onClose ? <div className='app-modal-close' onClick={onClose}>
                    <X size='2em' />
                </div> : null}
            </Modal.Header>
            <Modal.Body>
                <FormGroup>
                    <Form.Text>
                        You can enter Proxy <b>IDs</b> separated by comma. For specific <b>ID range</b> use inclusive start ID [dash] exclusive end ID. All spaces are omitted.
                        Duplicated proxies are skipped.
                        Not existing proxies are skipped.
                    </Form.Text>
                    <InputGroup>
                        <Form.Control onChange={onInputChange} placeholder='ex. 1,2,10-25,30' />
                        <InputGroup.Text>
                            <SimpleTooltip tooltip={inputRanges.message}>
                                {inputRanges.valid ? <CheckLg color='green'/> : <XLg color='red'/>}
                            </SimpleTooltip>
                        </InputGroup.Text>
                    </InputGroup>
                </FormGroup>
            </Modal.Body>
            <Modal.Footer>
                {props.actions.map((action, index) => {
                    return (
                        <Button disabled={!inputRanges.valid} key={`action-${index}`} variant={action.variant} onClick={() => onAction(action)}>
                            {action.title}
                        </Button>
                    )
                })}
            </Modal.Footer>
        </Modal>
    )
}