import {Variant} from "react-bootstrap/types";
import {DeltaInfo} from "../Utils/DeltaInfo";
import {ThemeProvider} from "react-bootstrap";
import propTypes = ThemeProvider.propTypes;

export class DateInfo {

    original: string
    utc: string
    local: string

    date: Date

    delta: DeltaInfo

    constructor(string: string) {
        const now = new Date();
        this.original = string;
        this.date = new Date(Date.parse(string));
        this.local = this.date.toLocaleString();
        this.utc = this.date.toUTCString()
        this.delta = new DeltaInfo(now.getTime() - this.date.getTime());
    }
}

export interface BotInfoDto {
    bot_id: number
    login: string,
    status: BotStatus
    create_date: string,
    update_date: string,
    sec_user_id?: string
    session_key?: string
    proxy?: ProxyDto | null
}

export class BotInfo {

    bot_id: number
    login: string
    status: BotStatus

    create_date: DateInfo
    update_date: DateInfo

    status_name: string
    status_bg: Variant

    sec_user_id?: string
    session_key?: string

    proxy: ProxyInfo | null

    constructor(dto: BotInfoDto) {
        this.bot_id = dto.bot_id;
        this.login = dto.login;
        this.status = dto.status;
        this.session_key = dto.session_key
        this.sec_user_id = dto.sec_user_id
        this.create_date = new DateInfo(dto.create_date)
        this.update_date = new DateInfo(dto.update_date)

        this.proxy = dto.proxy ? new ProxyInfo(dto.proxy) : null;

        switch (dto.status) {
            case BotStatus.BANNED:
                this.status_name = 'Banned'
                break;
            case BotStatus.EXPIRED:
                this.status_name = 'Expired'
                break;
            case BotStatus.ACTIVE:
                this.status_name = 'Active'
                break
            default:
                this.status_name = 'Unknown'
        }

        this.status_bg = getStatusBg(this.status)
    }
}

export interface BotListInfoDto {
    bots: BotInfoDto[],
    has_next: boolean,
    total_count: number
}

export class BotListInfo {

    bots: BotInfo[]
    has_next: boolean
    total_count: number

    constructor(dto: BotListInfoDto) {
        this.bots = dto.bots.map(dto => new BotInfo(dto))
        this.has_next = dto.has_next
        this.total_count = dto.total_count
    }
}

export interface BotCredentials {
    login: string
    password: string
    session_key?: string
}

export interface CreateBotCredentials {
    login: string
    password: string
    session_key?: string
}

export enum CollisionResolveStrategy {
    SKIP = 'skip',
    RAISE_ERROR = 'raise_error',
    UPDATE = 'update'
}

export enum BotStatus {
    BANNED = -2,
    EXPIRED = -1,
    ACTIVE = 1
}

export const BotStatuses = [BotStatus.ACTIVE, BotStatus.EXPIRED, BotStatus.BANNED]

function getStatusBg(status: BotStatus): Variant {
    switch (status) {
        case BotStatus.BANNED:
            return 'danger'
        case BotStatus.EXPIRED:
            return 'warning'
        case BotStatus.ACTIVE:
            return 'success'
        default:
            return 'primary'
    }
}

export interface BotCreateRequest {
    bot_creds: CreateBotCredentials[],
    collision_resolve_strategy: CollisionResolveStrategy
}

export enum TaskType {
    AUTH = 0,
    REUPLOAD_VIDEO = 1,
    SET_AVATAR = 2,
    SET_BIO = 3,
    SET_USERNAME = 4,
    SET_LOGIN_NAME = 5
}

function getTaskTypeBg(type: TaskType): Variant {
    switch (type) {
        case TaskType.SET_AVATAR:
            return 'info'
        case TaskType.SET_BIO:
            return 'info'
        case TaskType.SET_USERNAME:
            return 'warning'
        case TaskType.SET_LOGIN_NAME:
            return 'info'
        case TaskType.AUTH:
            return 'primary'
        case TaskType.REUPLOAD_VIDEO:
            return 'success'
        default:
            return 'dark'
    }
}

export enum TaskStatus {
    EXECUTE = 0,
    INTERRUPT = 1,
    COMPLETE = 2
}

function getTaskStatusBg(status: TaskStatus): Variant {
    switch (status) {
        case TaskStatus.EXECUTE:
            return 'primary'
        case TaskStatus.INTERRUPT:
            return 'danger'
        case TaskStatus.COMPLETE:
            return 'success'
        default:
            return 'dark'
    }
}

export enum TaskSourceType {
    AUTO = 0,
    USER = 1
}

export interface TaskInfoDto {
    task_id: number
    task_type: TaskType
    task_params: object
    status: TaskStatus,
    source: TaskSourceType,
    create_date: string
    update_date: string
}

export class TaskInfo {

    task_id: number
    task_type: TaskType
    task_params: object
    status: TaskStatus
    source: TaskSourceType
    create_date: DateInfo
    update_date: DateInfo

    task_type_name: string
    task_type_bg: Variant

    status_name: string
    status_bg: Variant

    is_auto_task: boolean
    params_count: number

    task_source_name: string
    can_interrupt: boolean

    constructor(dto: TaskInfoDto) {
        this.task_id = dto.task_id
        this.task_type = dto.task_type
        this.task_params = dto.task_params
        this.status = dto.status
        this.source = dto.source
        this.create_date = new DateInfo(dto.create_date)
        this.update_date = new DateInfo(dto.update_date)

        switch (this.task_type) {
            case TaskType.AUTH:
                this.task_type_name = 'Auth'
                break
            case TaskType.REUPLOAD_VIDEO:
                this.task_type_name = 'Upload'
                break;
            case TaskType.SET_AVATAR:
                this.task_type_name = 'Set Avatar'
                break;
            case TaskType.SET_BIO:
                this.task_type_name = 'Set Bio'
                break;
            case TaskType.SET_USERNAME:
                this.task_type_name = 'Set Username'
                break;
            case TaskType.SET_LOGIN_NAME:
                this.task_type_name = 'Set Login Name'
                break;
            default:
                this.task_type_name = 'Unknown'
                break
        }
        this.task_type_bg = getTaskTypeBg(this.task_type)

        switch (this.status) {
            case TaskStatus.EXECUTE:
                this.status_name = 'Execute'
                break
            case TaskStatus.INTERRUPT:
                this.status_name = 'Interrupt'
                break
            case TaskStatus.COMPLETE:
                this.status_name = 'Complete'
                break
            default:
                this.status_name = 'Unknown'
                break
        }
        this.status_bg = getTaskStatusBg(this.status);

        this.is_auto_task = this.source === TaskSourceType.AUTO
        this.params_count = Object.keys(this.task_params).length

        switch (this.source) {
            case TaskSourceType.AUTO:
                this.task_source_name = 'Automatically by the system'
                break
            case TaskSourceType.USER:
                this.task_source_name = 'User'
                break
            default:
                this.task_source_name = 'Unknown'
        }

        this.can_interrupt = this.status === TaskStatus.EXECUTE
    }
}

export interface TaskListInfoDto {
    tasks: TaskInfoDto[],
    has_next: boolean,
    total_count: number
}

export class TaskListInfo {

    tasks: TaskInfo[]
    has_next: boolean
    total_count: number

    constructor(dto: TaskListInfoDto) {
        this.tasks = dto.tasks.map(dto => new TaskInfo(dto))
        this.has_next = dto.has_next
        this.total_count = dto.total_count
    }
}

export interface GetTaskDto {
    task: TaskInfoDto
}

export class GetTask {
    task: TaskInfo

    constructor(dto: GetTaskDto) {
        this.task = new TaskInfo(dto.task)
    }
}

export enum SubTaskStatus {
    WAIT = 0,
    BLOCK = 1,
    EXECUTE = 2,
    SKIP = 3,
    FAIL = 4,
    INTERRUPT = 5,
    COMPLETE = 6,
    TRY_AGAIN = 7
}

export function getSubTaskStatusBg(status: SubTaskStatus): Variant {
    switch (status) {
        case SubTaskStatus.WAIT:
            return 'dark'
        case SubTaskStatus.BLOCK:
            return 'dark'
        case SubTaskStatus.EXECUTE:
            return 'primary'
        case SubTaskStatus.SKIP:
            return 'secondary'
        case SubTaskStatus.FAIL:
            return 'danger'
        case SubTaskStatus.INTERRUPT:
            return 'danger'
        case SubTaskStatus.COMPLETE:
            return 'success'
        case SubTaskStatus.TRY_AGAIN:
            return 'warning'
        default:
            return 'info'
    }
}

export interface SubTaskInfoDto {
    sub_task_id: number
    bot_id: number
    status: SubTaskStatus
}

export class SubTaskInfo {
    sub_task_id: number
    bot_id: number
    status: SubTaskStatus

    status_name: string
    status_bg: Variant

    constructor(dto: SubTaskInfoDto) {
        this.sub_task_id = dto.sub_task_id;
        this.bot_id = dto.bot_id;
        this.status = dto.status;

        switch (this.status) {
            case SubTaskStatus.WAIT:
                this.status_name = 'Wait'
                break
            case SubTaskStatus.BLOCK:
                this.status_name = 'Block'
                break
            case SubTaskStatus.EXECUTE:
                this.status_name = 'Execute'
                break;
            case SubTaskStatus.SKIP:
                this.status_name = 'Skip'
                break;
            case SubTaskStatus.FAIL:
                this.status_name = 'Fail'
                break;
            case SubTaskStatus.INTERRUPT:
                this.status_name = 'Interrupt'
                break;
            case SubTaskStatus.COMPLETE:
                this.status_name = 'Complete'
                break;
            case SubTaskStatus.TRY_AGAIN:
                this.status_name = 'Try again'
                break;
            default:
                this.status_name = 'Unknown'
        }
        this.status_bg = getSubTaskStatusBg(this.status)
    }
}

export interface SubTasksDto {
    sub_tasks: SubTaskInfoDto[],
    total_count: number,
    has_next: boolean
}

export class SubTasks {
    sub_tasks: SubTaskInfo[]
    total_count: number
    has_next: boolean

    constructor(dto: SubTasksDto) {
        this.sub_tasks = dto.sub_tasks.map(e => new SubTaskInfo(e))
        this.total_count = dto.total_count;
        this.has_next = dto.has_next;
    }
}

export interface ReUploadRequest {
    bot_ids: number[],
    params: { aweme_id: string }
}

export interface AddTaskResponse {
    task_id: number | null
}

export interface TaskInterruptResponse {
    status: string
}

export interface BotDeleteRequest {
    bot_ids: number[]
}

export interface BotDeleteResponse {
    removed_bot_ids: number[]
}

export enum BotGroupStatus {
    REMOVED = 0,
    ACTIVE = 1
}

export interface BotGroupDto {
    id: number
    name: string
    description: string
    status: BotGroupStatus
    bot_ids: number[]
    create_date: string
    update_date: string
}

export class BotGroup {
    id: number
    name: string
    description: string
    status: BotGroupStatus
    bot_ids: number[]
    create_date: DateInfo
    update_date: DateInfo

    variant: Variant
    status_name: string

    constructor(dto: BotGroupDto) {
        this.id = dto.id
        this.name = dto.name
        this.description = dto.description
        this.status = dto.status
        this.bot_ids = dto.bot_ids
        this.create_date = new DateInfo(dto.create_date)
        this.update_date = new DateInfo(dto.update_date)

        switch (this.status) {
            case BotGroupStatus.REMOVED:
                this.variant = 'danger'
                break;
            case BotGroupStatus.ACTIVE:
                this.variant = 'success'
                break;
            default:
                this.variant = 'dark';
                break;
        }
        switch (this.status) {
            case BotGroupStatus.ACTIVE:
                this.status_name = 'Active'
                break;
            case BotGroupStatus.REMOVED:
                this.status_name = 'Removed'
                break;
            default:
                this.status_name = 'Unknown'
                break;
        }
    }
}

export interface BotGroupListDto {
    groups: BotGroupDto[]
    has_next: boolean
    total_count: number
}

export class BotGroupList {
    groups: BotGroup[]
    has_next: boolean
    total_count: number

    constructor(dto: BotGroupListDto) {
        this.groups = dto.groups.map(e => new BotGroup(e))
        this.has_next = dto.has_next
        this.total_count = dto.total_count
    }
}

export interface AddBotGroupRequest {
    name: string
    description: string
}

export interface AddBotGroupResponse {
    created_group_id: number
}

export interface RemoveBotGroupResponse {
    removed_group_id: number
}

export interface AddGroupBotsRequest {
    bot_ids: number[]
}

export interface AddGroupBotsResponse {
    status: string
}

export interface UpdateBotGroupRequest {
    name?: string
    description?: string
}

export interface UpdateBotGroupResponse {
    updated_group_id: number
}

export interface RemoveBotsFromGroupRequest {
    bot_ids: number[]
}

export interface RemoveBotsFromGroupResponse {
    removed_bot_ids: number[]
}

export enum TaskExecutorType {
    BOT = 0,
    GROUP = 1
}

export enum ReuploadEditorType {
    DISTORTION = 0,
    SHORT_COMMENT = 1,
    COMMENT = 2,
    ADDITIONAL_CONTENT = 3
}

export const ReuploadEditorTypes = [
    ReuploadEditorType.DISTORTION,
    ReuploadEditorType.SHORT_COMMENT,
    ReuploadEditorType.COMMENT,
    ReuploadEditorType.ADDITIONAL_CONTENT
]

export function getReuploadEditorTypeName(type: ReuploadEditorType): string {
    switch (type) {
        case ReuploadEditorType.DISTORTION: return "Distortion"
        case ReuploadEditorType.SHORT_COMMENT: return "Short comment"
        case ReuploadEditorType.COMMENT: return "Comment"
        case ReuploadEditorType.ADDITIONAL_CONTENT: return "Additional content"
    }
}

export function getReuploadEditorTypeDescription(type: ReuploadEditorType): string {
    switch (type) {
        case ReuploadEditorType.DISTORTION:
            return 'Video will be cropped with random offset; noise and distortion effect will be applied'
        case ReuploadEditorType.SHORT_COMMENT:
            return 'Add short comment with emoji on video in random position (e.g. 😁LOL😁)'
        case ReuploadEditorType.COMMENT:
            return 'Add white borders with user comment on video'
        case ReuploadEditorType.ADDITIONAL_CONTENT:
            return 'Add additional content video on the bottom of video with gameplay, cooking or etc'
    }
}

export interface ReuploadVideoTaskData {
    aweme_id: string
    editor_type: ReuploadEditorType
}

export interface SetAvatarTaskData {
    avatar_base64: string
}

export interface SetBioTaskData {
    bio: string
}

export interface SetUsernameTaskData {
    username: string
}

export interface SetLoginNameTaskData {
    login_name: string
}

export type TaskData =
    ReuploadVideoTaskData |
    SetAvatarTaskData |
    SetBioTaskData |
    SetUsernameTaskData |
    SetLoginNameTaskData

export interface AddTaskRequest {
    executor_type: TaskExecutorType
    executor_ids: number[]
    task_type: TaskType
    task_data: TaskData
}

export enum ProxyStatus {
    ACTIVE = 1,
    DISABLED = -1
}

export interface ProxyDto {
    id: number
    data: string
    status: ProxyStatus
    create_date: string
    update_date: string
    fails_count: number
}

export interface ProxyListDto {
    proxies: ProxyDto[]
    total_count: number
    has_next: boolean
}

export function getProxyStatusVariant(status: ProxyStatus): Variant {
    switch (status) {
        case ProxyStatus.ACTIVE:
            return 'success';
        case ProxyStatus.DISABLED:
            return 'danger';
        default:
            return 'dark';
    }
}

export function getProxyStatusName(status: ProxyStatus): string {
    switch (status) {
        case ProxyStatus.ACTIVE:
            return 'Active'
        case ProxyStatus.DISABLED:
            return 'Disabled'
        default:
            return 'Unknown'
    }
}

export function getProxyFailsVariant(fails_count: number): Variant {
    if (fails_count <= 0) {
        return 'success'
    }
    if (fails_count < 20) {
        return 'warning'
    }
    return 'danger'
}

export class ProxyInfo {
    id: number
    data: string
    status: ProxyStatus
    create_date: DateInfo
    update_date: DateInfo
    fails_count: number

    variant: Variant
    status_name: string
    action: 'disable' | 'enable' | null
    fails_count_variant: Variant
    type: ProxyType | null

    constructor(dto: ProxyDto) {
        this.id = dto.id
        this.data = dto.data;
        this.status = dto.status;
        this.create_date = new DateInfo(dto.create_date);
        this.update_date = new DateInfo(dto.update_date);
        this.fails_count = dto.fails_count;


        this.status_name = getProxyStatusName(dto.status)
        this.variant = getProxyStatusVariant(dto.status)
        this.fails_count_variant = getProxyFailsVariant(dto.fails_count)

        this.action = dto.status === ProxyStatus.ACTIVE ? 'disable' : dto.status === ProxyStatus.DISABLED ? 'enable' : null;

        const uriParams = dto.data.split('://')
        const schema = uriParams.length === 2 ? uriParams[0] : null;
        this.type = PROXY_TYPES.find(e => e === schema?.toLowerCase()) ?? null
    }
}

export class ProxiesList {
    proxies: ProxyInfo[]
    total_count: number
    has_next: boolean

    constructor(dto: ProxyListDto) {
        this.proxies = dto.proxies.map(e => new ProxyInfo(e))
        this.total_count = dto.total_count
        this.has_next = dto.has_next
    }

}

export interface DisableProxiesRequest {
    ids: number[]
}

export interface DisableProxiesResponse {
    disabled_proxies: ProxyDto[]
}

export interface EnableProxiesRequest {
    proxies: number[]
}

export interface EnableProxiesResponse {
    enabled_proxies: ProxyDto[]
}

export interface ChangeProxiesRequest {
    enable: EnableProxiesRequest
    disable: DisableProxiesRequest
}

export interface ChangeProxiesStatusResponse extends EnableProxiesResponse, DisableProxiesResponse {
}

export enum ProxyType {
    SOCKS5 = 'socks5',
    HTTP = 'http',
    HTTPS = 'https'
}

export const PROXY_TYPES = [ProxyType.SOCKS5, ProxyType.HTTP, ProxyType.HTTPS]

export function getProxyTypeName(proxyType: ProxyType): string {
    switch (proxyType) {
        case ProxyType.HTTP: return 'HTTP'
        case ProxyType.SOCKS5: return 'SOCKS5'
        case ProxyType.HTTPS: return 'HTTPS'
        default: return proxyType
    }
}

export interface ProxyData {
    schema?: string
    host: string
    port: number
    username: string | undefined
    password: string | undefined
}

export interface AddProxiesRequest {
    proxies: string[]
}

export interface AddProxiesResponse {
    added_proxies: ProxyDto[]
}

export interface SetBotProxyRequest {
    proxy_id: number
}

export interface SetBotProxyResponse {
    status: string
}

export interface UserInfo {
    token: string
    user_id: number
    username: string
}

export interface UserLoginRequest {
    username: string,
    password: string
}

export interface UserSignUpRequest {
    username: string
    password: string
}