import {
    ChangeProxiesRequest,
    ChangeProxiesStatusResponse,
    ProxyInfo
} from "../Core/Model";
import {Button, Modal, Spinner, Table} from "react-bootstrap";
import {SimpleTooltip} from "../Utils/SimpleTooltip";
import {useApi} from "../Core/ApiContextProvider";
import {callDispatch, CallState, CallUpdate, newCall} from "../Utils/Calls";
import {useMemo, useReducer, useState} from "react";
import {BlockTitle} from "../Utils/BlockTitle";
import {ErrorView} from "../Utils/ErrorView";
import {IdRange, inflateIds} from "../Utils/Utils";

type ProxyDescriptor =  IdRange | ProxyInfo

export type ChangeProxyStatusModalProxyList = {
    type: 'RANGES',
    enable: IdRange[],
    disable: IdRange[]
} | {
    type: 'PROXIES',
    enable: ProxyInfo[],
    disable: ProxyInfo[]
}


export interface ChangeProxyStatusModalProps {
    proxies: ChangeProxyStatusModalProxyList
    // eslint-disable-next-line no-unused-vars
    onClose: (changed: boolean) => void
}

interface ChangeProxyStatusState {
    change: CallState<ChangeProxiesStatusResponse>
}

type ChangeProxyStatusEvent = {
    type: 'CALL_UPDATE',
    call: 'change',
    update: CallUpdate
}

function newState(): ChangeProxyStatusState {
    return {
        change: newCall()
    }
}

const ChangeProxyRow = (props: { descriptor: ProxyDescriptor }) => {
    if (props.descriptor instanceof ProxyInfo) {
        const proxy = props.descriptor
        return (
            <tr key={`proxy-disable-${proxy.id}`}>
                <td>
                    {proxy.id}
                </td>
                <td>
                    <SimpleTooltip tooltip={<div>UTC: {proxy.create_date.utc}</div>}>
                        {proxy.create_date.local}
                    </SimpleTooltip>
                </td>
                <td>
                    {proxy.data}
                </td>
            </tr>
        )
    } else {
        const range = props.descriptor
        return (
            <tr key={`proxy-disable-${range}`}>
                <td>
                    {range.type === 'id' ? `${range.id}` : `${range.start}-${range.end}`}
                </td>
                <td>
                    N/A
                </td>
                <td>
                    N/A
                </td>
            </tr>
        )
    }
}

function ChangeProxyList(props: { listKey: string, title: string, proxies: ProxyDescriptor[] }) {
    const proxyAmount = 10
    const [shownProxy, setShownProxysetShownProxy] =
        useState<ProxyDescriptor[]>(props.proxies.slice(0, proxyAmount))
    const enableShowMore = props.proxies.length > proxyAmount
    const onShowAll = () => {
        if (shownProxy.length < props.proxies.length) {
            setShownProxysetShownProxy(props.proxies)
        } else {
            setShownProxysetShownProxy(props.proxies.slice(0, proxyAmount))
        }
    }
    return (
        <>
            <BlockTitle title={props.title} />
            {enableShowMore ? <Button onClick={onShowAll} variant='outline-primary' size='sm'>
                {shownProxy.length < props.proxies.length ? `Show all ${props.proxies.length} proxies` : `Minimize`}
            </Button> : null}
            <Table size='sm'>
                <thead>
                <tr>
                    <th>ID/Range</th>
                    <th>Created</th>
                    <th>Data</th>
                </tr>
                </thead>
                <tbody>
                    {shownProxy.map((proxy, index) => {
                        return <ChangeProxyRow key={`proxy-${props.listKey}-${index}`} descriptor={proxy} />
                    })}
                </tbody>
            </Table>
        </>
    )
}

function createChangeRequest(list: ChangeProxyStatusModalProxyList): ChangeProxiesRequest {
    if (list.type === 'PROXIES') {
        const fn = (infos: ProxyInfo[]) => infos.map(e => e.id)
        return {
            enable: { proxies: fn(list.enable)} ,
            disable: { ids: fn(list.disable) }
        }
    } else {
        const fn = inflateIds
        return {
            enable: { proxies: fn(list.enable)} ,
            disable: { ids: fn(list.disable) }
        }
    }
}

export const ChangeProxyStatusModal = (props: ChangeProxyStatusModalProps) => {
    const api = useApi()
    const [state, dispatchState] = useReducer((state: ChangeProxyStatusState, event: ChangeProxyStatusEvent) => {
        switch (event.type) {
            case "CALL_UPDATE": {
                switch (event.call) {
                    case "change":
                        return { ...state, change: callDispatch(state.change, event.update) }
                }
                break;
            }
        }
        return state;
    }, newState())
    const totalProxies = useMemo(() => {
        console.log('recalculate total')
        if (props.proxies.type === 'PROXIES') {
            return props.proxies.disable.length + props.proxies.enable.length
        } else {
            return inflateIds(props.proxies.enable).length + inflateIds(props.proxies.disable).length
        }
    }, [props.proxies])
    const onProceed = () => {
        dispatchState({ type: 'CALL_UPDATE', call: 'change', update: { type: 'LOADING', message: 'Changes proxies status...' } })
        api.proxiesStatusChange(createChangeRequest(props.proxies))
            .then(e => dispatchState({ type: 'CALL_UPDATE', call: 'change', update: { type: 'DATA', data: e } }))
            .catch(e => dispatchState({ type: 'CALL_UPDATE', call: 'change', update: { type: 'ERROR', error: e } }))
    }
    const onClose = () => {
        // currently, since there are two requests to perform 'change' operation
        // only one of the requests might fail so changed applied by succeeded request
        // not being rolled back, so list must be refreshed even when error has occurred
        props.onClose(state.change.data !== null || state.change.error !== null)
    }
    const getContent = () => {
        if (state.change.loading) {
            return (
                <div className='loading-wrapper'>
                    <Spinner/>
                    {state.change.message !== null && <span className='loading-message'>
                        {state.change.message}
                   </span>}
                </div>
            )
        }

        if (state.change.error) {
            return (
                <ErrorView call={state.change} name={'change status of proxies'} additional={['Some proxies may be modified due this call.']} />
            )
        }

        if (state.change.data) {
            return (
                <div className='success-message'>
                    <div>
                        <div>Proxies status successfully changed.</div>
                        {props.proxies.enable.length > 0 && <div>{state.change.data.enabled_proxies.length} proxies has been <span className='text-success'>enabled</span></div>}
                        {props.proxies.disable.length > 0 && <div>{state.change.data.disabled_proxies.length} proxies has been <span className='text-danger'>disabled</span></div>}
                    </div>
                </div>
            )
        }
        const toBeDisabled = () => {
            if (props.proxies.disable.length > 0) {
                return <ChangeProxyList listKey={'disable'} title={'To be disabled'} proxies={props.proxies.disable} />
            }
            return null
        }
        const toBeEnabled = () => {
            if (props.proxies.enable.length > 0) {
                return <ChangeProxyList listKey={'enable'} title={'To be enabled'} proxies={props.proxies.enable} />
            }
            return null
        }
        return (<>{toBeDisabled()}{toBeEnabled()}</>)
    }

    const getFooter = () => {
        if (state.change.data && !state.change.loading) {
            return (
                <Button variant='primary' onClick={onClose}>
                    Close
                </Button>
            )
        }
        if (state.change.error && !state.change.loading) {
            return (
                <>
                    <Button variant='primary' onClick={onProceed}>
                        Try again
                    </Button>
                    <Button variant='outline-primary' onClick={onClose}>
                        Close
                    </Button>
                </>
            )
        }
        return (
            <>
                <Button variant='primary' onClick={onProceed} disabled={state.change.loading}>
                    Proceed
                </Button>
                <Button variant='outline-danger' onClick={onClose} disabled={state.change.loading}>
                    Cancel
                </Button>
            </>
        )
    }

    return (
        <Modal show={true} size='lg' fullscreen='lg-down'>
            <Modal.Header>
                <Modal.Title>Change status of {totalProxies} proxies</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {getContent()}
            </Modal.Body>
            <Modal.Footer>
                {getFooter()}
            </Modal.Footer>
        </Modal>
    )
}