import React, {ChangeEvent, useEffect, useState} from "react";
import {FieldState} from "../../Utils/FieldState";
import {Dropdown, Form, FormControl, InputGroup} from "react-bootstrap";
import {SimpleTooltip} from "../../Utils/SimpleTooltip";
import {CheckLg, Question, QuestionLg, XLg} from "react-bootstrap-icons";
import {useCreateTask} from "./CreateTaskContext";
import {
    getReuploadEditorTypeDescription,
    getReuploadEditorTypeName,
    ReuploadEditorType,
    ReuploadEditorTypes
} from "../../Core/Model";

export const CreateUploadTask = () => {
    const createTask = useCreateTask()
    const [awemeValid, setAwemeValid] = useState<FieldState<string>>({ valid: false, message: 'You need to enter aweme ID of the video' })
    const [editorType, setEditorType] = useState<ReuploadEditorType>(ReuploadEditorType.DISTORTION)
    const onAwemeChange = (event: ChangeEvent<HTMLInputElement>) => {
        const value = event.target.value.trim()
        if (value.length === 0) {
            setAwemeValid({ valid: false, message: 'You need to enter aweme ID of the video' })
            return
        }
        setAwemeValid({ valid: true, message: 'Ok', data: value })
    }
    const onEditorTypeChange = (eventKey: string | null) => {
        if (eventKey === null) {
            return
        }
        console.warn(`onEditorTypeChange eventKey="${eventKey}"`)
        const newType = ReuploadEditorTypes.find(e => e.toString() === eventKey.toString())
        console.warn(`onEditorTypeChange eventKey="${eventKey} newType=${newType}"`)
        if (newType !== undefined) {
            setEditorType(newType)
        }
    }

    useEffect(() => {
        if (awemeValid.valid) {
            createTask.dispatchData({ aweme_id: awemeValid.data, editor_type: editorType })
        } else {
            createTask.dispatchInvalid()
        }
    }, [awemeValid, editorType])

    return (
        <div>
            <InputGroup>
                <InputGroup.Text>Aweme ID</InputGroup.Text>
                <FormControl onChange={onAwemeChange} type='text' placeholder='ex. 7165099726885457178'/>
                <InputGroup.Text>
                    <SimpleTooltip tooltip={awemeValid.message}>
                        {awemeValid.valid ? <CheckLg color='green'/> : <XLg color='red'/>}
                    </SimpleTooltip>
                </InputGroup.Text>
            </InputGroup>
            <InputGroup className='mt-2'>
                <InputGroup.Text>Editor Type</InputGroup.Text>
                <Dropdown onSelect={onEditorTypeChange}>
                    <Dropdown.Toggle as='div' className='form-control cursor-pointer' style={{flex: '1 1 auto', textAlign: 'start'}}>
                        {getReuploadEditorTypeName(editorType)}
                    </Dropdown.Toggle>
                    <Dropdown.Menu>
                        {ReuploadEditorTypes.map(e => <Dropdown.Item eventKey={e} key={e}>
                            {getReuploadEditorTypeName(e)}
                        </Dropdown.Item>)}
                    </Dropdown.Menu>
                </Dropdown>
                <InputGroup.Text>
                    <SimpleTooltip tooltip={getReuploadEditorTypeDescription(editorType)}>
                        <QuestionLg />
                    </SimpleTooltip>
                </InputGroup.Text>
            </InputGroup>
        </div>
    )
}