import {Badge, Button, Col, Container, Row, Spinner, Table} from "react-bootstrap";
import {BlockTitle} from "../Utils/BlockTitle";
import {callDispatch, CallState, CallUpdate, newCall} from "../Utils/Calls";
import {GetTask, SubTasks} from "../Core/Model";
import {useApi} from "../Core/ApiContextProvider";
import {useEffect, useReducer} from "react";
import {ArrowRepeat, LightningFill} from "react-bootstrap-icons";
import {SimpleTooltip} from "../Utils/SimpleTooltip";
import {PaginationDirectionControls} from "../Utils/Pagination";
import {useCache} from "../Utils/UserCache";
import {ClosableProps} from "../Utils/Closable";
import {ShowMore} from "../Utils/ShowMore";
import {TableErrorView} from "../Utils/TableErrorView";

export interface TaskDetailsProps extends ClosableProps {
    taskId: number
}

interface TaskDetailsState {
    get: CallState<GetTask>,
    subtasks: CallState<SubTasks>
}

type TaskDetailsEvent =
    {
        type: 'CALL_UPDATE',
        call: 'get' | 'subtasks',
        data: CallUpdate
    };

function newState(limit: number): TaskDetailsState {
    return { get: newCall(), subtasks: newCall(limit) }
}

export const TaskDetails = (props: TaskDetailsProps) => {
    const cache = useCache()
    const api = useApi()
    const [state, dispatchState] = useReducer((state: TaskDetailsState, event: TaskDetailsEvent) => {
        switch (event.type) {
            case "CALL_UPDATE": {
                switch (event.call) {
                    case "get":
                        return { ...state, get: callDispatch(state.get, event.data) }
                    case "subtasks":
                        return  { ...state, subtasks: callDispatch(state.subtasks, event.data) }
                }
            }
        }
        return state
    }, newState(cache.number('subtasks_limit', 10)));
    useEffect(() => {
        const abortController = new AbortController()
        dispatchState({ type: 'CALL_UPDATE', call: 'get', data: { type: 'LOADING', message: 'Loading...' } })
        api.taskGet(props.taskId, abortController)
            .then(e => dispatchState({ type: 'CALL_UPDATE', call: 'get', data: { type: 'DATA', data: e } }))
            .catch(e => !abortController.signal.aborted && dispatchState({ type: 'CALL_UPDATE', call: 'get', data: { type: 'ERROR', error: e } }))
        return () => abortController.abort()
    }, [props.taskId, state.get.refreshIndicator])
    useEffect(() => {
        const abortController = new AbortController()
        dispatchState({ type: 'CALL_UPDATE', call: 'subtasks', data: { type: 'LOADING', message: 'Loading...' } })
        api.taskSubtasksGet(props.taskId, state.subtasks.pagination.offset, state.subtasks.pagination.limit, abortController)
            .then(e => dispatchState({ type: 'CALL_UPDATE', call: 'subtasks', data: { type: 'DATA', data: e } }))
            .catch(e => !abortController.signal.aborted && dispatchState({ type: 'CALL_UPDATE', call: 'subtasks', data: { type: 'ERROR', error: e } }))
        return () => abortController.abort()
    }, [props.taskId, state.subtasks.pagination.offset, state.subtasks.pagination.limit, state.subtasks.refreshIndicator])
    return (
        <Container className='app-tab-content'>
            <Row>
                <Col>
                    <BlockTitle
                        closeType={props.closeType}
                        onClose={props.onClose}
                        title={`Uploads > #${props.taskId}`}
                        description={
                        state.get.loading ? state.get.message :
                            state.get.error ? `Failed to load: ${state.get.error.message}` :
                                state.get.data ? '' : ''
                    } />
                    <div className='table-toolbar'>
                        <Button disabled={state.subtasks.loading} onClick={() => dispatchState({ type: 'CALL_UPDATE', call: 'get', data: { type: 'REFRESH' } })}>
                            <div className='icon-button'>
                                <ArrowRepeat size='1.2em' title='Refresh' />
                            </div>
                        </Button>
                    </div>
                    <Row>
                        <Col>
                            <BlockTitle title='Summary' description={''} />
                            <Table>
                                {state.get.data !== null && !state.get.loading ? <tbody>
                                    <tr>
                                        <td className='col-3'>Created</td>
                                        <td className='col-3'>{state.get.data.task.create_date.local}</td>
                                    </tr>
                                    <tr>
                                        <td className='col-3'>Updated</td>
                                        <td className='col-3'>{state.get.data.task.update_date.local}</td>
                                    </tr>
                                    <tr>
                                        <td className='col-3'>Type</td>
                                        <td className='col-3'>{state.get.data.task.task_type_name}</td>
                                    </tr>
                                    <tr>
                                        <td className='col-3'>Current status</td>
                                        <td className='col-3'>{state.get.data.task.status_name}</td>
                                    </tr>
                                    <tr>
                                        <td className='col-3'>Scheduled by</td>
                                        <td className='col-3'>{state.get.data.task.is_auto_task ? <LightningFill /> : null} {state.get.data.task.task_source_name}</td>
                                    </tr>
                                </tbody> : null}
                            </Table>
                        </Col>
                        <Col>
                            <BlockTitle title='Task Params' description={''} />
                            <Table>
                                {state.get.data !== null && !state.get.loading ? <tbody>
                                {state.get.data.task.params_count > 0? Object.entries(state.get.data.task.task_params).map(([key, value], index) => {
                                    return (
                                        <tr key={`task-param-${index}`}>
                                            <td>{key}</td>
                                            <td>
                                                {typeof value === 'object' ?
                                                    <div>Multiple values</div> :
                                                    <ShowMore value={Array.isArray(value) ?
                                                        value.join(', ') :
                                                        value.toString()} />
                                                }
                                            </td>
                                        </tr>
                                    )
                                }) : <tr>
                                    <td colSpan={2} className='center-text'>No params</td>
                                </tr>}
                                </tbody> : null}
                            </Table>
                        </Col>
                    </Row>
                </Col>
            </Row>
            <div>
                <BlockTitle title={`Subtasks`} description={
                    state.subtasks.loading ? state.subtasks.message :
                        state.subtasks.error ? `Failed to load: ${state.subtasks.error.message}` :
                            state.subtasks.data ? `${state.subtasks.data.total_count} subtasks found` : ''
                } />
                <div className='table-wrapper'>
                    <div className='table-toolbar'>
                        <Button disabled={state.subtasks.loading} onClick={() => dispatchState({ type: 'CALL_UPDATE', call: 'subtasks', data: { type: 'REFRESH' } })}>
                            <div className='icon-button'>
                                <ArrowRepeat size='1.2em' title='Refresh' />
                            </div>
                        </Button>
                    </div>
                    <div className='overflow-table-wrapper'>
                        <Table striped hover className='full-width'>
                            <thead>
                            <tr>
                                <th>
                                    ID
                                </th>
                                <th>
                                    Bot
                                </th>
                                <th>
                                    Status
                                </th>
                            </tr>
                            </thead>
                            <tbody>
                            {state.subtasks.data !== null && state.subtasks.data.sub_tasks.length > 0 ?
                                state.subtasks.data.sub_tasks.map(task => {
                                    return (
                                        <tr key={task.sub_task_id}>
                                            <td>
                                                {task.sub_task_id}
                                            </td>
                                            <td>
                                                {task.bot_id}
                                            </td>
                                            <td>
                                                <SimpleTooltip tooltip={<div>Status ID: {task.status}</div>}>
                                                    <Badge bg={task.status_bg}>{task.status_name}</Badge>
                                                </SimpleTooltip>
                                            </td>
                                        </tr>
                                    )
                                }) : null}
                            {state.subtasks.data !== null && state.subtasks.data.sub_tasks.length === 0 ?
                                <tr>
                                    <td colSpan={5} className='center-text'>
                                        Empty list of sub tasks
                                    </td>
                                </tr> : null}
                            <TableErrorView call={state.subtasks} name={'loading sub tasks'} colSpan={6} />
                            </tbody>
                        </Table>
                    </div>
                    <PaginationDirectionControls
                        call={state.subtasks}
                        show={!state.subtasks.loading}
                        totalCountFn={(data) => data.total_count}
                        onPaginationControl={(d) => dispatchState({ type: 'CALL_UPDATE', call: 'subtasks', data: { type: 'PAGINATION', direction: d } })}
                        onPaginationLimit={(limit) => dispatchState({ type: 'CALL_UPDATE', call: 'subtasks', data: { type: 'LIMIT', limit: cache.setNumber('subtasks_limit', limit) } })} />
                    {state.subtasks.loading ? <div className='loading-wrapper'>
                        <Spinner />
                        {state.subtasks.message !== null && <span className='loading-message'>
                        {state.subtasks.message}
                    </span>}
                    </div> : null}
                </div>
            </div>
        </Container>
    )
}