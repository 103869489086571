import React, {ReactNode, useContext, useEffect} from "react";
import {Api, ApiError} from "./Api";
import {useAuthContext} from "./AuthContextProvider";

export const ApiContext = React.createContext<Api | null>(null);

export const useApi: () => Api = () => {
    const api = useContext(ApiContext)
    if (api === null) {
        throw new ApiError(null, 'Api is not found', null)
    }
    return api
}

export interface ApiProviderProps {
    baseUrl: string,
    children?: ReactNode | undefined
}

export const ApiProvider = ({ baseUrl, children }: ApiProviderProps) => {
    const {authState} = useAuthContext()
    const [api, setApi] = React.useState(new Api(baseUrl, authState.user?.token ?? null));
    useEffect(() => {
        setApi(new Api(baseUrl, authState.user?.token ?? null))
        console.warn(`api has been refreshed on user ${authState.user?.username ?? '<null>'}`)
    }, [authState.user])
    return (
        <ApiContext.Provider value={api} >
            {children}
        </ApiContext.Provider>
    )
}