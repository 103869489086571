import {useCache} from "../Utils/UserCache";
import {useApi} from "../Core/ApiContextProvider";
import {useEffect, useReducer} from "react";
import {callDispatch, CallState, CallUpdate, newCallWithMetadata} from "../Utils/Calls";
import {getProxyTypeName, ProxiesList, ProxyData, ProxyInfo, ProxyStatus} from "../Core/Model";
import {Badge, Button, Dropdown, Form, Spinner, Table} from "react-bootstrap";
import {ArrowRepeat, CheckLg, FolderPlus, HandIndex, Icon123, PlusLg, ToggleOff, XLg} from "react-bootstrap-icons";
import {SimpleTooltip} from "../Utils/SimpleTooltip";
import {DotsToggle} from "../Utils/DotsToggle";
import {PaginationDirectionControls} from "../Utils/Pagination";
import {ChangeProxyStatusModal, ChangeProxyStatusModalProxyList} from "./ChangeProxyStatus";
import {CreateProxyModal} from "./CreateProxy";
import {TableErrorView} from "../Utils/TableErrorView";
import {OpenFileDialog} from "../Utils/OpenFile/OpenFile";
import {ProxyFileParser} from "../Core/ProxyFileParser";
import {SelectProxiesModal} from "./SelectProxiesIds";
import {IdRange, inflateIds} from "../Utils/Utils";

const ProxyStatuses = [ProxyStatus.DISABLED, ProxyStatus.ACTIVE]

interface ProxyListMetadata {
    selectedStatuses: ProxyStatus[]
}

interface ProxyListState {
    proxies: CallState<ProxiesList, ProxyListMetadata>
    changeStatusMode: boolean
    changeStatusProxies: ProxyInfo[]
    changeProxiesStatusModalParams: { proxies: ChangeProxyStatusModalProxyList } | null,
    createModal: { show: boolean, proxies: ProxyData[] }
    openProxyFileModal: { show: boolean }
    proxiesSelectModal: { show: boolean }
}

type ProxyListEvent = {
    type: 'CALL_UPDATE',
    call: 'proxies',
    update: CallUpdate
} | {
    type: 'TOGGLE_CHANGE_MODE'
} | {
    type: 'TOGGLE_PROXY_SELECT',
    item: ProxyInfo
} | {
    type: 'SUBMIT_CHANGE',
    proxies: ProxyInfo[]
} | {
    type: 'CLOSE_CHANGE_STATUS_MODAL',
    update: boolean
} | {
    type: 'SHOW_CREATE_MODAL',
    show: boolean,
    update: boolean,
    proxies: ProxyData[]
} | {
    type: 'SHOW_OPEN_FILES_MODAL',
    show: boolean
} | {
    type: 'SHOW_PROXIES_SELECT_MODAL',
    show: boolean
} | {
    type: 'OPEN_CHANGE_STATUS_MODAL',
    enable: IdRange[],
    disable: IdRange[]
}

function newState(limit: number, statuses: ProxyStatus[]): ProxyListState {
    return {
        proxies: newCallWithMetadata({ selectedStatuses: statuses }, limit),
        changeStatusMode: false,
        changeStatusProxies: [],
        changeProxiesStatusModalParams: null,
        createModal: { show: false, proxies: [] },
        openProxyFileModal: { show: false },
        proxiesSelectModal: { show: false }
    }
}

export const ProxyList = () => {
    const cache = useCache()
    const api = useApi()
    const [state, dispatchState] = useReducer((state: ProxyListState, event: ProxyListEvent) => {
        if (event.type === 'CALL_UPDATE') {
            switch (event.call) {
                case "proxies":
                    return { ...state, proxies: callDispatch(state.proxies, event.update) }
            }
        } else if (event.type === 'TOGGLE_CHANGE_MODE') {
            return { ...state, changeStatusMode: !state.changeStatusMode, changeStatusProxies: [] }
        } else if (event.type === 'TOGGLE_PROXY_SELECT') {
            const isDisableSelected = state.changeStatusProxies.find(e => e.id === event.item.id) !== undefined
            const newItems = isDisableSelected ?
                state.changeStatusProxies.filter(e => e.id !== event.item.id) :
                [...state.changeStatusProxies, event.item]
            return { ...state, changeStatusProxies: newItems }
        } else if (event.type === "SUBMIT_CHANGE") {
            const enable = event.proxies.filter(e => e.status === ProxyStatus.DISABLED)
            const disable = event.proxies.filter(e => e.status === ProxyStatus.ACTIVE)
            const proxies: ChangeProxyStatusModalProxyList = { type: 'PROXIES', enable: enable, disable: disable }
            return { ...state, changeStatusMode: false, changeStatusProxies: [], changeProxiesStatusModalParams: { proxies: proxies } }
        } else if (event.type === 'CLOSE_CHANGE_STATUS_MODAL') {
            const refreshIndicator = event.update ? !state.proxies.refreshIndicator : state.proxies.refreshIndicator
            return { ...state, changeStatusMode: false, changeStatusProxies: [], changeProxiesStatusModalParams: null, proxies: { ...state.proxies, refreshIndicator: refreshIndicator } }
        } else if (event.type === 'SHOW_CREATE_MODAL') {
            return { ...state, createModal: { show: event.show, proxies: event.proxies }, openProxyFileModal: { show: false }, proxies: { ...state.proxies, refreshIndicator: event.update? !state.proxies.refreshIndicator : state.proxies.refreshIndicator }}
        } else if (event.type === 'SHOW_OPEN_FILES_MODAL') {
            return { ...state, openProxyFileModal: { show: event.show } }
        } else if (event.type === 'SHOW_PROXIES_SELECT_MODAL') {
            return { ...state, proxiesSelectModal: { show: event.show} }
        } else if (event.type === 'OPEN_CHANGE_STATUS_MODAL') {
            const proxies: ChangeProxyStatusModalProxyList = { type: 'RANGES', enable: event.enable, disable: event.disable }
            return { ...state, changeStatusMode: false, changeStatusProxies: [], changeProxiesStatusModalParams: { proxies: proxies } }
        }
        return state
    }, newState(cache.number('proxies_limit', 10), cache.json('proxy_statuses', ProxyStatuses)))
    const onStatusSelect = (status: ProxyStatus, isSelected: boolean) => {
        const currentStatuses = state.proxies.metadata.selectedStatuses
        const newSelectedStatuses = isSelected ?
            currentStatuses.filter(e => e !== status) :
            [...currentStatuses, status]
        const newMetadata = { ...state.proxies.metadata, selectedStatuses: cache.setJson('proxy_statuses', newSelectedStatuses) }
        dispatchState({ type: 'CALL_UPDATE', call: 'proxies', update: { type: 'SET_METADATA', metadata: newMetadata }})
    }
    const onProxyChangeStatus = (proxy: ProxyInfo) => {
        dispatchState({ type: 'SUBMIT_CHANGE', proxies: [proxy] })
    }
    const onProxyEnableDisableChoice = (eventKey: string | null) => {
        if (eventKey === 'proxy-change-manually') {
            dispatchState({type: 'TOGGLE_CHANGE_MODE', update: false})
        } else if (eventKey === 'proxy-change-dialog') {
            dispatchState({type: 'SHOW_PROXIES_SELECT_MODAL', show: true})
        } else {
            // fallback
            dispatchState({type: 'TOGGLE_CHANGE_MODE', update: false})
        }
    }
    useEffect(() => {
        const controller = new AbortController()
        dispatchState({ type: 'CALL_UPDATE', call: 'proxies', update: { type: 'LOADING', message: 'Proxy are loading...' } })
        api.proxiesList(state.proxies.pagination.offset, state.proxies.pagination.limit, state.proxies.metadata.selectedStatuses, controller)
            .then(botList => dispatchState({ type: 'CALL_UPDATE', call: 'proxies', update: { type: 'DATA', data: botList } }))
            .catch(error => !controller.signal.aborted && dispatchState({ type: 'CALL_UPDATE', call: 'proxies', update: { type: 'ERROR', error: error } }))
        return () => controller.abort()
    }, [state.proxies.refreshIndicator, state.proxies.pagination.limit, state.proxies.pagination.offset, state.proxies.metadata])
    return (
        <>
            {state.changeProxiesStatusModalParams && <ChangeProxyStatusModal
                onClose={(changed) => dispatchState({ type: 'CLOSE_CHANGE_STATUS_MODAL', update: changed })}
                {...state.changeProxiesStatusModalParams /* enable and disable props */} />}
            <SelectProxiesModal
                show={state.proxiesSelectModal.show}
                actions={[
                    { title: 'Disable all', variant: 'danger', handler: (r) => dispatchState({ type: 'OPEN_CHANGE_STATUS_MODAL', disable: r, enable: [] }) },
                    { title: 'Enable all', variant: 'success', handler: (r) => dispatchState({ type: 'OPEN_CHANGE_STATUS_MODAL', enable: r, disable: [] }) },
                ]}
                onClose={() => dispatchState({type: 'SHOW_PROXIES_SELECT_MODAL', show: false})} />
            <CreateProxyModal
                show={state.createModal.show}
                initialProxies={state.createModal.proxies}
                onClose={(changed) => dispatchState({ type: 'SHOW_CREATE_MODAL', show: false, proxies: [], update: changed })} />
            <OpenFileDialog
                title={"Add proxies from file"}
                continueString={(n) => `Continue with ${n} proxies`}
                columnName={"Proxies"}
                parser={new ProxyFileParser()}
                show={state.openProxyFileModal.show}
                onClose={() => dispatchState({ type: 'SHOW_OPEN_FILES_MODAL', show: false })}
                onContinue={(proxies) => dispatchState({ type: 'SHOW_CREATE_MODAL', show: true, update: false, proxies: proxies })} />
            <div className='table-wrapper'>
                <div className='table-toolbar'>
                    <Button disabled={state.proxies.loading} onClick={() => dispatchState({ type: 'CALL_UPDATE', call: 'proxies', update: { type: 'REFRESH' } })}>
                        <div className='icon-button'>
                            <ArrowRepeat size='1.2em' title='Refresh' />
                        </div>
                    </Button>
                    <Button disabled={state.proxies.loading} onClick={() => dispatchState({ type: 'SHOW_CREATE_MODAL', show: true, proxies: [], update: false })}>
                        <div className='icon-button'>
                            <PlusLg size='1.2em' title='Add' />
                            <span>Add proxies</span>
                        </div>
                    </Button>
                    <Button disabled={state.proxies.loading} onClick={() => dispatchState({ type: 'SHOW_OPEN_FILES_MODAL', show: true })}>
                        <div className='icon-button'>
                            <FolderPlus />
                            <span>Add proxies from file</span>
                        </div>
                    </Button>
                    <Dropdown autoClose={'outside'}>
                        <Dropdown.Toggle>Filter: {
                            state.proxies.metadata.selectedStatuses.length === 0 ?
                                'All statuses' :
                                state.proxies.metadata.selectedStatuses.length === 1 ?
                                    ProxyStatus[state.proxies.metadata.selectedStatuses[0]] :
                                    `${state.proxies.metadata.selectedStatuses.length} statuses`}
                        </Dropdown.Toggle>
                        <Dropdown.Menu >
                            {
                                ProxyStatuses.map(key => {
                                    const isSelected = state.proxies.metadata.selectedStatuses.includes(key)
                                    return (
                                        <Dropdown.Item eventKey={key} key={`status-${key}`} onClick={() => onStatusSelect(key, isSelected)}>
                                            <Form.Check inline checked={isSelected} label={ProxyStatus[key]} readOnly/>
                                        </Dropdown.Item>
                                    )
                                })
                            }
                        </Dropdown.Menu>
                    </Dropdown>
                    {state.changeStatusMode ? <>
                        <Button variant='primary' disabled={state.changeStatusProxies.length === 0 || state.proxies.loading} onClick={() => dispatchState({ type: 'SUBMIT_CHANGE', proxies: state.changeStatusProxies })}>
                            <div className='icon-button'>
                                <ToggleOff size='1.2em' title='Submit change' />
                                <span>Change {state.changeStatusProxies.length} proxies</span>
                            </div>
                        </Button>
                        <Button variant='outline-danger' disabled={state.proxies.loading} onClick={() => dispatchState({ type: 'TOGGLE_CHANGE_MODE', update: false })}>
                            <div className='icon-button'>
                                <XLg size='1.2em' title='Cancel change' />
                                <span>Cancel change</span>
                            </div>
                        </Button>
                    </> :
                        <Dropdown autoClose={'outside'} onSelect={onProxyEnableDisableChoice}>
                            <Dropdown.Toggle className='icon-button' disabled={state.proxies.loading} variant='outline-primary'>
                                <ToggleOff size='1.2em' title='Enable/Disable' />
                                <span>Enable/Disable</span>
                            </Dropdown.Toggle>
                            <Dropdown.Menu>
                                <Dropdown.Item disabled={state.proxies.loading} eventKey={'proxy-change-manually'}>
                                    <div className='icon-button'>
                                        <HandIndex style={{marginRight: '4px'}} />
                                        <span>Manually</span>
                                    </div>
                                </Dropdown.Item>
                                <Dropdown.Item disabled={state.proxies.loading} eventKey={'proxy-change-dialog'}>
                                    <div className='icon-button'>
                                        <Icon123 style={{marginRight: '4px'}} />
                                        <span>By IDs</span>
                                    </div>
                                </Dropdown.Item>
                            </Dropdown.Menu>
                        </Dropdown>}
                </div>
                <div className='overflow-table-wrapper'>
                    <Table striped hover className='full-width'>
                        <thead>
                        <tr>
                            <th>
                                ID
                            </th>
                            <th>
                                Created
                            </th>
                            <th>
                                Type
                            </th>
                            <th>
                                Data
                            </th>
                            <th>
                                Status
                            </th>
                            <th>
                                Fails
                            </th>
                            <th></th>
                        </tr>
                        </thead>
                        <tbody>
                        {state.proxies.data !== null && state.proxies.data.proxies.length > 0 ?
                            state.proxies.data.proxies.map(proxy => {
                                const isChangeSelected = state.changeStatusMode && (state.changeStatusProxies.find(e => e.id === proxy.id) !== undefined)
                                const isActive = proxy.status === ProxyStatus.ACTIVE
                                return (
                                    <tr className='cursor-pointer' key={proxy.id}>
                                        <td className='col-1'>
                                            {proxy.id}
                                        </td>
                                        <td className='col-3'>
                                            <SimpleTooltip tooltip={<div>UTC: {proxy.create_date.utc}</div>}>
                                                {proxy.create_date.local}
                                            </SimpleTooltip>
                                        </td>
                                        <td className='col-1'>
                                            {proxy.type ? getProxyTypeName(proxy.type) : <SimpleTooltip tooltip='Default proxy type is SOCKS5'>N/A</SimpleTooltip>}
                                        </td>
                                        <td className='col-8'>
                                            {proxy.data}
                                        </td>
                                        <td className='col-1'>
                                            <SimpleTooltip tooltip={<div>Status ID: {proxy.status}</div>}>
                                                <Badge bg={proxy.variant}>{proxy.status_name}</Badge>
                                            </SimpleTooltip>
                                        </td>
                                        <td className='col-1'>
                                            <SimpleTooltip tooltip={<div>{proxy.fails_count} requests failed on this proxy</div>}>
                                                <Badge pill bg={proxy.fails_count_variant}>{proxy.fails_count}</Badge>
                                            </SimpleTooltip>
                                        </td>
                                        <td className='col-1'>
                                            {state.changeStatusMode ?
                                                <Button size='sm' variant={isChangeSelected ? isActive ? 'danger' : 'success' : isActive ? 'outline-danger' : 'outline-success'} onClick={() => dispatchState({ type: 'TOGGLE_PROXY_SELECT', item: proxy })}>
                                                    {isActive ? <XLg /> : <CheckLg />}
                                                </Button> : <Dropdown drop='start'>
                                                <Dropdown.Toggle as={DotsToggle}></Dropdown.Toggle>
                                                <Dropdown.Menu>
                                                    <Dropdown.ItemText>
                                                        Proxy {proxy.id}
                                                    </Dropdown.ItemText>
                                                    <Dropdown.Divider />
                                                    {proxy.action === 'enable' ? <Dropdown.Item className='text-success' onClick={() => onProxyChangeStatus(proxy)}>
                                                        Enable
                                                    </Dropdown.Item> : proxy.action === 'disable' ? <Dropdown.Item className='text-danger' onClick={() => onProxyChangeStatus(proxy)}>
                                                        Disable
                                                    </Dropdown.Item> : <Dropdown.Item disabled>
                                                        No actions available
                                                    </Dropdown.Item>}
                                                </Dropdown.Menu>
                                            </Dropdown>}
                                        </td>
                                    </tr>
                                )
                            }) : null}
                        {state.proxies.data !== null && state.proxies.data.proxies.length === 0 ?
                            <tr>
                                <td colSpan={5} className='center-text'>
                                    Empty list of proxies
                                </td>
                            </tr> : null}
                        <TableErrorView colSpan={6} call={state.proxies} name={'proxy loading'} />
                        </tbody>
                    </Table>
                </div>
                <PaginationDirectionControls
                    call={state.proxies}
                    show={!state.proxies.loading}
                    totalCountFn={(data) => data.total_count}
                    onPaginationControl={(d) => dispatchState({ type: 'CALL_UPDATE', call: 'proxies', update: { type: 'PAGINATION', direction: d } })}
                    onPaginationLimit={(limit) => dispatchState({ type: 'CALL_UPDATE', call: 'proxies', update: { type: 'LIMIT', limit: cache.setNumber('proxies_limit', limit) } })} />
                {state.proxies.loading ? <div className='loading-wrapper'>
                    <Spinner />
                    {state.proxies.message !== null && <span className='loading-message'>
                        {state.proxies.message}
                    </span>}
                </div> : null}
            </div>
        </>
    )
}