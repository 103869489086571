import {useAuthContext} from "../Core/AuthContextProvider";
import {Navigate} from "react-router-dom";

export const HomeRouter = () => {
    const { authState } = useAuthContext()
    if (authState.user === null) {
        return <Navigate to='/login' />
    } else {
        return <Navigate to='/dashboard' />
    }
}