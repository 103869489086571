import {FileParseException, FileParser} from "./FileParser";
import {PROXY_TYPES, ProxyData} from "./Model";

export class ProxyFileParser implements FileParser<ProxyData> {

    static parsePort(port: string): number {
        if (port.length === 0) {
            throw new Error(`Port could not be empty`)
        }
        const number = Number(port)
        if (Number.isNaN(number)) {
            throw new Error(`Port should be a number, got NaN`);
        }
        if (!Number.isInteger(number)) {
            throw new Error(`Port should be an integer, got ${number}`)
        }
        if (number <= 0 || number >= 65536) {
            throw new Error(`Port should be in range (0, 65536), got ${number}`)
        }
        return number;
    }

    static validateSchema(schema: string): boolean {
        if (schema.length === 0) {
            return true
        }
        const type = PROXY_TYPES.find(e => e === schema.toLowerCase())
        return type !== undefined
    }

    parseFileContent(content: string): ProxyData[] {
        const lines = content.split("\n")
        let proxies: ProxyData[] = []
        for (let i = 0; i < lines.length; i++) {
            try {
                const line = lines[i].trim()
                if (line.length === 0) {
                    continue;
                }
                const uriParams = line.split('://')
                if (uriParams.length < 1 || uriParams.length > 2) {
                    // noinspection ExceptionCaughtLocallyJS
                    throw new FileParseException(`File parse failure on line ${i = 1}: Unexpected multiple occurrences of :// in line`)
                }
                const [schema, uri] = uriParams.length === 2 ? uriParams : ['', uriParams[0]]
                const parts = uri.split(":")
                if (parts.length < 2) {
                    // noinspection ExceptionCaughtLocallyJS
                    throw new FileParseException(`File parse failure on line ${i + 1}: Not enough parts separated by ':' - ${parts.length}`)
                }
                if (!ProxyFileParser.validateSchema(schema)) {
                    // noinspection ExceptionCaughtLocallyJS
                    throw new FileParseException(`File parse failure on line ${i + 1}: Schema '${schema}' is not supported`)
                }
                const host = parts[0];
                const port = ProxyFileParser.parsePort(parts[1].trim());
                const username = parts.length > 2 ? parts[2] : undefined;
                const password = parts.length > 3 ? parts[3] : undefined;
                proxies.push({ schema, host, port, username, password })
            } catch (e) {
                if (e instanceof FileParseException) {
                    throw e
                }
                throw new FileParseException(`File parse failure on line ${i + 1}: ${e}`);
            }
        }
        return proxies;
    }
}