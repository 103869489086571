import {useAuthContext} from "../Core/AuthContextProvider";
import {Button} from "react-bootstrap";
import {CallState} from "./Calls";
import {ExclamationCircle} from "react-bootstrap-icons";

export interface ErrorViewProps<T> {
    call: CallState<T>
    name: string
    additional?: string[]
}

export function ErrorView<T>(props: ErrorViewProps<T>) {
    const {clearAuth} = useAuthContext()
    if (props.call.error !== null && !props.call.loading) {
        const isAuthError = props.call.error.response && [401, 403].includes(props.call.error.response.status)
        return (
            <div className='error-message mt-2'>
                <ExclamationCircle size='1.2em' color='red' />
                <div>
                    {isAuthError && <div>Authorization error occurred performing {props.name}. Consider log out and log in again or contact support!</div>}
                    {!isAuthError && <div>Error occurred performing {props.name}</div>}
                    <div>{props.call.error.message}</div>
                    {props.additional && props.additional.map((message, idx) => <div key={idx}>{message}</div>)}
                    {isAuthError && <div><Button size='sm' variant='outline-danger' onClick={() => clearAuth}>Logout</Button></div>}
                </div>
            </div>
        )
    }
    return null
}