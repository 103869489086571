import {Button, FormControl, Modal, Table} from "react-bootstrap";
import {X} from "react-bootstrap-icons";
import {ChangeEvent, useReducer} from "react";
import {dispatchFileState, FileEvent, FileState, FileView, newFileState} from "./File";
import {FileParser} from "../../Core/FileParser";

export interface OpenFileDialogProps<T> {
    title: string
    continueString: (count: number) => string
    columnName: string
    show: boolean,
    parser: FileParser<T>,
    // eslint-disable-next-line no-unused-vars
    onClose: () => void,
    // eslint-disable-next-line no-unused-vars
    onContinue: (bots: T[]) => void
}

interface OpenFile<T> {
    file: File,
    state: FileState<T>
}

type OpenFileDialogTotalState = 'empty' | 'valid' | 'errored' | 'loading'

interface OpenFileDialogState<T> {
    files: OpenFile<T>[],
    totalState: OpenFileDialogTotalState,
    result: T[]
}

type OpenFileDialogEvent<T> =
    {
        type: 'OPEN_FILE',
        file: File
    } |
    {
        type: 'FILE_EVENT',
        index: number,
        event: FileEvent<T>
    } |
    {
        type: 'REMOVE_FILE',
        index: number
    } |
    {
        type: 'RESET'
    }

function newState<T>(): OpenFileDialogState<T> {
    return {
        files: [],
        totalState: 'empty',
        result: []
    }
}

function calculateResult<T>(files: OpenFile<T>[]): T[] {
    return files.flatMap(file => {
        if (file.state.fileState.type === 'success') {
            return file.state.fileState.items
        }
        return []
    })
}

function calculateErrors<T>(files: OpenFile<T>[]): OpenFileDialogTotalState {
    if (files.length === 0) {
        return 'empty'
    }
    for (const { state } of files) {
        if (state.fileState.type === 'error') {
            return 'errored'
        }
        if (state.fileState.type === 'loading') {
            return 'loading'
        }
    }
    return 'valid'
}

export function OpenFileDialog<T>(props: OpenFileDialogProps<T>) {
    const [state, dispatchState] = useReducer((state: OpenFileDialogState<T>, event: OpenFileDialogEvent<T>) => {
        switch (event.type) {
            case 'OPEN_FILE': {
                const files = [...state.files, {file: event.file, state: newFileState<T>()}]
                return { ...state, files: files, totalState: calculateErrors(files), result: calculateResult(files) }
            }
            case 'REMOVE_FILE': {
                const files = state.files.filter((_, index) => index !== event.index)
                return { ...state, files: files, totalState: calculateErrors(files), result: calculateResult(files) }
            }
            case 'FILE_EVENT': {
                let file = state.files[event.index]
                let files = [...state.files]
                files[event.index] = { ...file, state: dispatchFileState<T>(file.state, event.event) }
                return { ...state, files: files, totalState: calculateErrors(files), result: calculateResult(files) }
            }
            case 'RESET': {
                return newState<T>();
            }
        }
        return state
    }, newState<T>());
    const onClose = () => {
        dispatchState({ type: 'RESET' })
        props.onClose()
    }
    const onContinue = (result: T[]) => {
        props.onContinue(result)
        dispatchState({ type: 'RESET' })
    }
    const onFileInputChange = (event: ChangeEvent<HTMLInputElement>) => {
        if (event.target.files !== undefined && event.target.files !== null && event.target.files.length > 0) {
            const file = event.target.files[0]
            dispatchState({ type: 'OPEN_FILE', file: file })
            event.target.value = ''
        }
    }
    return (
        <Modal show={props.show}>
            <Modal.Header>
                <Modal.Title>
                    {props.title}
                </Modal.Title>
                <div className='app-modal-close' onClick={() => onClose()}>
                    <X size='2em' />
                </div>
            </Modal.Header>
            <Modal.Body>
                <div className='modal-table'>
                    <Table size='sm'>
                        <thead>
                        <tr>
                            <th className='bot-create-table-cell'>File</th>
                            <th className='bot-create-table-cell'>State</th>
                            <th className='bot-create-table-cell'>{props.columnName}</th>
                            <th className='bot-create-table-cell'></th>
                        </tr>
                        </thead>
                        <tbody>
                        {
                            state.files.map((openFile, index) => <FileView
                                key={`open-bot-file-${index}`}
                                parser={props.parser}
                                file={openFile.file}
                                state={openFile.state}
                                dispatchState={(e) => dispatchState({ type: 'FILE_EVENT', index: index, event: e })}
                                onRemove={() => dispatchState({ type: 'REMOVE_FILE', index: index })} />)
                        }
                        </tbody>
                    </Table>
                </div>
                <FormControl type='file' onChange={onFileInputChange} />
            </Modal.Body>
            <Modal.Footer>
                {state.totalState === 'empty' ? 'Add files to continue' : null}
                {state.totalState === 'errored' ? 'Some of the files failed to load. Remove them to continue' : null}
                {state.totalState === 'loading' ? 'Some of the files are loading...' : null}
                {state.totalState === 'valid' ?
                    <Button onClick={() => onContinue(state.result)} disabled={state.result.length === 0} variant='success'>
                        {props.continueString(state.result.length)}
                    </Button> : null}
            </Modal.Footer>
        </Modal>
    )
}