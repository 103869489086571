import {Button, Card, Col, Container, Form, FormGroup, InputGroup, Row, Spinner} from "react-bootstrap";

import './Styles/Login.css'
import {Eye, EyeSlash, Person} from "react-bootstrap-icons";
import {useEffect, useReducer, useState} from "react";
import {SimpleTooltip} from "../Utils/SimpleTooltip";
import {FormEvent} from 'react';
import {FieldState} from "../Utils/FieldState";
import {useApi} from "../Core/ApiContextProvider";
import {callDispatch, CallState, CallUpdate, newCall} from "../Utils/Calls";
import {UserInfo} from "../Core/Model";
import {useAuthContext} from "../Core/AuthContextProvider";
import {useNavigate} from "react-router-dom";

interface FormState {
    username: FieldState<string>
    password: FieldState<string>
}

const validateUsername = (formData: FormData): FieldState<string> => {
    const username = formData.get('username')
    if (typeof username !== 'string' || username.length < 3) {
        return { valid: false, message: 'Enter a valid username' }
    }
    return { valid: true, message: 'Ok', data: username }
}

const validatePassword = (formData: FormData): FieldState<string> => {
    const password = formData.get('password')
    if (typeof password !== 'string' || password.length < 5) {
        return { valid: false, message: 'Enter a valid password' }
    }
    return { valid: true, message: 'Ok', data: password }
}

export const Login = () => {
    const navigate = useNavigate()
    const api = useApi()
    const {setAuth} = useAuthContext()
    const [isSignUp, setSignUp] = useState<boolean>(false)
    const [passwordToggle, setPasswordToggle] = useState<boolean>(false)
    const [formState, setFormState] = useState<FormState>({ username: { valid: true, message: '', data: '' }, password: { valid: true, message: '', data: '' } })
    const [callState, dispatchCall] = useReducer((state: CallState<UserInfo>, action: CallUpdate) => {
        return callDispatch(state, action)
    }, newCall<UserInfo>())
    const onSubmit = (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault()
        const form = e.currentTarget
        const formData = new FormData(form)
        const username = validateUsername(formData)
        const password = validatePassword(formData)
        setFormState({ username, password })
        if (!username.valid || !password.valid) {
            return
        }

        dispatchCall({ type: 'LOADING', message: isSignUp ? 'Creating an account...' : 'Signing in...' })
        const promise = isSignUp ?
            api.signUp({ username: username.data, password: password.data }) :
            api.login({ username: username.data, password: password.data })
        promise.then(e => dispatchCall({ type: 'DATA', data: e }))
            .catch(e => dispatchCall({ type: 'ERROR', error: e }))
    }
    useEffect(() => {
        if (!callState.loading && !callState.error && callState.data) {
            setAuth(callState.data)
            navigate('/')
        }
    }, [callState])

    return (
        <Container>
            <Row>
                <Col
                    xs={{ span: 10, offset: 1 }}
                    md={{ span: 6, offset: 3 }}
                    lg={{ span: 4, offset: 4 }}>
                    <Row>
                        <Card className='login_card'>
                            <Card.Body>
                                <Form onSubmit={onSubmit} noValidate>
                                    <Form.Group>
                                        <Form.Group className="mb-3">
                                            <Form.Label>Username</Form.Label>
                                            <InputGroup hasValidation>
                                                <Form.Control disabled={callState.loading} isInvalid={!formState.username.valid} name='username' placeholder='John' />
                                                <InputGroup.Text>
                                                    <Person />
                                                </InputGroup.Text>
                                                <Form.Control.Feedback type={'invalid'}>
                                                    {formState.username.message}
                                                </Form.Control.Feedback>
                                            </InputGroup>
                                        </Form.Group>
                                        <Form.Group className="mb-3">
                                            <Form.Label>Password</Form.Label>
                                            <InputGroup hasValidation>
                                                <Form.Control disabled={callState.loading} isInvalid={!formState.password.valid} name='password' placeholder='***********' type={passwordToggle ? 'text' : 'password'} />
                                                <InputGroup.Text className='password_eye' onClick={() => !callState.loading && setPasswordToggle(!passwordToggle)}>
                                                    <SimpleTooltip tooltip={passwordToggle ? 'Hide password' : 'Show password'}>
                                                        {passwordToggle ? <EyeSlash  /> : <Eye />}
                                                    </SimpleTooltip>
                                                </InputGroup.Text>
                                                <Form.Control.Feedback type={'invalid'}>
                                                    {formState.password.message}
                                                </Form.Control.Feedback>
                                            </InputGroup>
                                        </Form.Group>
                                        <FormGroup>
                                            <Form.Control as={Button} variant={isSignUp ? 'success' : 'primary'} type='submit' disabled={callState.loading}>
                                                {callState.loading ?
                                                    <div className='d-flex flex-row justify-content-center align-items-center gap-2'>
                                                        <Spinner size='sm' />
                                                        <span>{callState.message}</span>
                                                    </div>
                                                    : <>{isSignUp ? `Sign Up` : `Sign In`}</>}
                                            </Form.Control>
                                            {!callState.loading && callState.error && <small className='text-danger login_error'>
                                                {callState.error.response?.status === 401 ?
                                                    'Incorrect credentials' :
                                                callState.error.response?.status === 404 ?
                                                    'User could not be found' :
                                                callState.error.message}
                                            </small>}
                                        </FormGroup>
                                    </Form.Group>
                                </Form>
                                <div className='full-width d-flex flex-column'>
                                    <Button size='sm' variant='link' onClick={() => setSignUp(!isSignUp)}>
                                        {isSignUp ? `I have an account` : `Create a new account`}
                                    </Button>
                                </div>
                            </Card.Body>
                        </Card>
                    </Row>
                </Col>
            </Row>
        </Container>
    )
}