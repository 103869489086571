import {BotsDescriptor} from "../Bots/BotsInput";
import {TaskExecutorType} from "../Core/Model";

export function undefinedIfEmpty(string: string): string | undefined {
    if (string.trim().length === 0) {
        return undefined
    }
    return string
}

export function toInteger(string: string): number | null {
    try {
        const value = Number(string)
        if (!Number.isInteger(value)) {
            return null
        }
        if (!Number.isFinite(value)) {
            return null
        }
        if (Number.isNaN(value)) {
            return null
        }
        return value
    } catch (e) {
        return null
    }
}

export type IdRange =
    { type: 'id', id: number } | { type: 'range', start: number, end: number }

export function parseIds(value: string, rangeLimit: number = 1000): IdRange[] {
    if (value.length === 0) {
        throw new Error('You need to select at least one bot')
    }
    const segments = value.split(',').map(e => e.trim())
    let ids: IdRange[] = []
    for (let index = 0; index < segments.length; index++) {
        const segment = segments[index]
        if (segment.length === 0) {
            continue
        }
        const separationIndex = segment.indexOf('-')
        if (separationIndex >= 0) {
            const startValue = segment.substring(0, separationIndex).trim()
            const endValue = segment.substring(separationIndex + 1, segment.length).trim()
            if (startValue.length === 0) {
                throw new Error(`Range ${index + 1}: Value before '-' is empty`)
            }
            if (endValue.length === 0) {
                throw new Error(`Range ${index + 1}: Value after '-' is empty`)
            }
            const start = toInteger(startValue)
            const end = toInteger(endValue)
            if (start === null) {
                throw new Error(`Range ${index + 1}: Value before '-' is not a valid integer`)
            }
            if (end === null) {
                throw new Error(`Range ${index + 1}: Value after '-' is not a valid integer`)
            }
            if (start < 0) {
                throw new Error(`Range ${index + 1}: Value before '-' cannot be negative`)
            }
            if (end < 0) {
                throw new Error(`Range ${index + 1}: Value after '-' cannot be negative`)
            }
            if (start > end) {
                throw new Error(`Range ${index + 1}: Start value must be less or equal to end value`)
            }
            if (end - start > rangeLimit) {
                throw new Error(`Range ${index + 1}: Maximum allowed range is ${rangeLimit} < ${end - start}`)
            }
            ids.push({ type: 'range', start: start, end: end })
        } else {
            const idValue = toInteger(segment)
            if (idValue === null) {
                throw new Error(`Value ${index + 1}: Not a valid integer`)
            }
            ids.push({ type: 'id', id: idValue })
        }
    }
    return ids
}

export function inflateIds(ranges: IdRange[]): number[] {
    return ranges.flatMap(range => {
        switch (range.type) {
            case 'id':
                return [range.id]
            case 'range': {
                let ids: number[] = []
                for (let i = range.start; i < range.end; i++) {
                    ids.push(i)
                }
                return ids
            }
        }
    })
}

export function inflateBots(descriptor: BotsDescriptor): number[] {
    if (descriptor.type === 'bot') {
        return inflateIds(descriptor.bots)
    } else if (descriptor.type === 'group') {
        return descriptor.groups.flatMap(group => {
            return group.bot_ids
        })
    }
    return []
}

export function inflateExecutors(descriptor: BotsDescriptor): [TaskExecutorType, number[]] {
    if (descriptor.type === 'bot') {
        return [TaskExecutorType.BOT, inflateBots(descriptor)]
    } else if (descriptor.type === 'group') {
        return [TaskExecutorType.GROUP, descriptor.groups.map(group => group.id)]
    }
    return [TaskExecutorType.BOT, []]
}