import React, {ReactNode} from "react";

export type CacheStorage = 'localStorage' | 'sessionStorage' | 'none'
export type CacheType = 'json' | 'string' | 'number' | 'bool'
export type Cache = 'dashboard_page' | 'bots_limit' | 'tasks_limit' | 'bots_statuses' | 'tasks_show_auto' | 'subtasks_limit' |
    'groups_limit' | 'group_bots_limit' | 'create_task_key' | 'proxies_limit' | 'proxy_statuses' | 'auth_state'

class MemoryCache extends Storage {

    private data: any = {}

    setItem(key: string, value: string) {
        this.data[key] = value
    }

    getItem(key: string): string | null {
        const v = this.data[key]
        if (v === undefined || v === null) {
            return null
        }
        return v as string
    }

    removeItem(key: string) {
        this.data[key] = undefined
    }
}

export class UserCache {

    storage: Storage

    constructor(storageType: CacheStorage) {
        switch (storageType) {
            case 'localStorage':
                this.storage = localStorage
                break
            case 'sessionStorage':
                this.storage = sessionStorage
                break
            case 'none':
                this.storage = new MemoryCache()
                break
        }
    }

    private get(type: CacheType, cache: Cache): string | null {
        return this.storage.getItem(`cache.${type}.${cache}`)
    }

    private set(type: CacheType, cache: Cache, value: string) {
        this.storage.setItem(`cache.${type}.${cache}`, value)
    }

    json<T>(cache: Cache, defaultValue: T): T {
        const value = this.get('json', cache)
        if (value === null || value.length === 0) {
            return defaultValue
        }
        return JSON.parse(value)
    }

    value(cache: Cache, defaultValue: string): string {
        const value = this.get('string', cache)
        if (value === null || value.length === 0) {
            return defaultValue
        }
        return value
    }

    number(cache: Cache, defaultValue: number): number {
        const value = this.get('number', cache)
        if (value === null || value.length === 0) {
            return defaultValue
        }
        try {
            const number = Number(value)
            if (Number.isNaN(number)) {
                return defaultValue
            }
            return number
        } catch (e) {
            return defaultValue
        }
    }

    boolean(cache: Cache, defaultValue: boolean): boolean {
        const value = this.get('bool', cache)
        if (value === null || value.length === 0) {
            return defaultValue
        }
        return value === 'true' ? true : value === 'false' ? false : defaultValue;
    }

    setValue(cache: Cache, value: string): string {
        this.set('string', cache, value)
        return value
    }

    setNumber(cache: Cache, value: number): number {
        this.set('number', cache, value.toString())
        return value
    }

    setJson<T>(cache: Cache, value: T): T {
        const json = JSON.stringify(value)
        this.set('json', cache, json)
        return value
    }

    setBoolean(cache: Cache, value: boolean): boolean {
        this.set('bool', cache, value.toString())
        return value
    }

    remove(cache: Cache) {
        this.storage.removeItem(cache)
    }
}

export const UserCacheContext = React.createContext<UserCache>(new UserCache('localStorage'));

export interface UserCacheProviderProps {
    children: ReactNode | undefined | null
}

export const UserCacheProvider = (props: UserCacheProviderProps) => {
    const [useCache] = React.useState(new UserCache('localStorage'))
    return (
        <UserCacheContext.Provider value={useCache}>
            {props.children}
        </UserCacheContext.Provider>
    )
}

export const useCache = () => React.useContext(UserCacheContext)