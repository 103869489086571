import React from 'react';
import ReactDOM from 'react-dom/client';
import { App } from './App/App';
import {AuthProvider} from "./Core/AuthContextProvider";

// import boostrap styles for whole project
import 'bootstrap/dist/css/bootstrap.min.css';
import './index.css';
import {DevBadge} from "./Utils/DevBadge";

const root = ReactDOM.createRoot(
    document.getElementById('root') as HTMLElement
);
root.render(
    <React.StrictMode>
        <AuthProvider>
            <App />
            <DevBadge />
        </AuthProvider>
    </React.StrictMode>
);

