import React from "react";
import {Navigate, Outlet} from "react-router-dom";
import {useAuthContext} from "../Core/AuthContextProvider";

export const AuthGuard = () => {
    const { authState } = useAuthContext()
    if (authState.user === null) {
        return (
            <Navigate to='/login' />
        )
    }
    return (
        <Outlet />
    )
}