import {Button, Container, Dropdown, Nav, Navbar, NavDropdown} from "react-bootstrap";
import {Link, Outlet} from "react-router-dom";
import {useAuthContext} from "../Core/AuthContextProvider";
import {Person} from "react-bootstrap-icons";

export const NavOutlet = () => {
    const {authState, clearAuth} = useAuthContext()
    return (
        <>
            <Navbar bg="dark" variant="dark">
                <Container>
                    <Navbar.Brand as={Link} to='/'>
                                  <span className="no-select">
                                      TikTok Upload
                                  </span>
                    </Navbar.Brand>
                    <Nav className="me-auto">
                        <Nav.Link as={Link} to='/dashboard'>
                            Dashboard
                        </Nav.Link>
                    </Nav>
                    <Nav className='me-end'>
                        {authState.user === null ?
                            <Nav.Link as={Link} to='/login'>
                                Login
                            </Nav.Link> :
                            <NavDropdown title={<span>
                                <Person size='1.2em' />
                                <span style={{ marginLeft: '4px' }}>{authState.user.username}</span>
                            </span>}>
                                <NavDropdown.Item onClick={() => clearAuth()}>
                                    Logout
                                </NavDropdown.Item>
                            </NavDropdown>
                        }
                    </Nav>
                </Container>
            </Navbar>
            <div className='app-root'>
                <Container>
                    <Outlet />
                </Container>
            </div>
        </>
    )
}